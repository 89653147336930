import React, { FC, memo, useCallback } from 'react';
import { Badge, Button, Typography } from 'front-package-ui-kit';
import { useAppSelector, useBottomSheet, useParseWebView } from '../../../hooks';
import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI, DividerUI, ImageCover } from '../..';
import { Box, Stack } from '@mui/material';
import { ProIcon } from './ProIcon';
import { ImagePaths, ResizeType, ValuesOfConstants } from '../../../utils';
import Slider from 'react-slick';
import { StarIcon } from '../../../assets/icons';
import defaultCover from '../../../assets/image/defaultCover.png';
import parse from 'html-react-parser';

import s from './styles.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const TEST_ID = 'KolsaProDetailsBottomSheet';

export const openKolsaProDetailsBottomSheet = 'openKolsaProDetailsBottomSheet';

const settingsSlider = {
  dots: true,
  infinite: true,
  speed: 500,
  swipeToSlide: true,
  slickGoTo: true,
  swipe: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 7000,
};

export interface KolsaProDetailsBottomSheetProps {}

const KolsaProDetailsBottomSheet: FC<KolsaProDetailsBottomSheetProps> = () => {
  const { grades } = useAppSelector((state) => state.gradeStore);

  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const isOpen = getIsOpenBottomSheet(openKolsaProDetailsBottomSheet);

  const handleClose = useCallback(() => onCloseBottomSheet(), [onCloseBottomSheet]);

  const [textProgramm, textWorkout] = useParseWebView([
    ValuesOfConstants.bottomSheetPaymentProgramm,
    ValuesOfConstants.bottomSheetPaymentWorkout,
  ]);

  return (
    <BottomSheetUI
      open={isOpen}
      touchLine
      height="auto"
      onDismiss={handleClose}
      expandOnContentDrag
      header={<BottomSheetHeader onClose={handleClose} />}
      footer={
        <BottomButtonBlock>
          <Button size="L" color="lime" onClick={handleClose} variant="contained" fullWidth>
            <Typography variant="text6" fontWeight={700}>
              Бросить вызов!
            </Typography>
          </Button>
        </BottomButtonBlock>
      }
    >
      <div className={s.kolsaProDetailsBottomSheet}>
        <Stack direction="row" justifyContent="center" alignItems="center" columnGap="8px" marginBottom="24px">
          <Typography variant="h4" fontWeight={800} textTransform="uppercase">
            Kolsa
          </Typography>

          <Badge size="M" color="grey100">
            <Typography color="white" variant="text6" textTransform="uppercase" fontWeight={700} marginRight="2px">
              pro
            </Typography>

            <ProIcon />
          </Badge>
        </Stack>

        <div className={s.containerInform}>
          <div className={s.text}>{textProgramm}</div>

          <DividerUI color="grey" />
        </div>

        <div className={s.containerInform}>
          <div className={s.text}>{textWorkout}</div>

          <DividerUI color="grey" />
        </div>

        <Slider className={s.slider} {...settingsSlider}>
          {grades.map((grade) => (
            <Stack key={`${grade.user_name}-${grade.id}`} className={s.reviewContainer} columnGap="14px">
              <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom="14px">
                <Stack direction="row">
                  {new Array(grade.rating).fill(null).map((_, index) => (
                    <StarIcon key={`${grade.user_name}-${index}`} />
                  ))}
                </Stack>

                <Stack direction="row" columnGap="8px" alignItems="center">
                  <Typography variant="text7">{grade.user_name}</Typography>

                  <Box className={s.photoContainer}>
                    <ImageCover
                      img={{
                        baseApiService: 'APP',
                        imagePath: ImagePaths.gradePhoto,
                        name: grade.user_photo,
                        width: 72,
                        height: 72,
                        resize: ResizeType.resizeBothCenter,
                      }}
                      defaultImg={defaultCover}
                    />
                  </Box>
                </Stack>
              </Stack>

              <div className={s.textContainer}>{parse(grade.text, { trim: true })}</div>
            </Stack>
          ))}
        </Slider>
      </div>
    </BottomSheetUI>
  );
};

export const KolsaProDetailsBottomSheetMemo = memo(KolsaProDetailsBottomSheet);
