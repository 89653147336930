import { FC, ReactNode } from 'react';
import { Drawer, SpringEvent } from '../../Drawer';

export const TEST_ID = 'BottomSheet';

interface PropTypes {
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  onDismiss: () => void;
  open: boolean;
  expandOnContentDrag?: boolean;
  height: 'auto' | 'full';
  touchLine?: boolean;
  transparentHeader?: boolean;
  background?: 'white' | 'grey';
  onSpring?: (event: SpringEvent) => void;
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  theme?: 'dark' | 'light';
}

const BottomSheetUI: FC<PropTypes> = ({
  children,
  touchLine,
  height,
  header,
  transparentHeader,
  footer,
  open,
  expandOnContentDrag,
  theme,
  onSpring,
  onScroll,
  ...props
}) => {
  return (
    <Drawer
      isOpen={open}
      onClose={() => props.onDismiss?.()}
      onSpring={onSpring}
      header={header}
      footer={footer}
      onScroll={onScroll}
      transparentHeader={transparentHeader}
      theme={theme}
      dataTestid={TEST_ID}
    >
      {children}
    </Drawer>
  );
};
BottomSheetUI.displayName = 'BottomSheetUI';

export default BottomSheetUI;
