import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CoursesTabButtonsValues } from './config';

interface ICourses {
  currentTab: CoursesTabButtonsValues;
  hideCourseTabButtons: boolean;
  searchValue: string;
  isOpenSearch: boolean;
}

const initialState: ICourses = {
  currentTab: CoursesTabButtonsValues.all,
  hideCourseTabButtons: false,
  searchValue: '',
  isOpenSearch: false,
};

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setCurrentTab(state, { payload }: PayloadAction<CoursesTabButtonsValues>) {
      state.currentTab = payload;
    },
    setHideCourseTabButtons(state, { payload }: PayloadAction<boolean>) {
      state.hideCourseTabButtons = payload;
    },
    setSearchValue(state, { payload }: PayloadAction<string>) {
      state.searchValue = payload;
    },
    setIsOpenSearch(state, { payload }: PayloadAction<boolean>) {
      state.isOpenSearch = payload;
    },
  },
});

export const { setCurrentTab, setHideCourseTabButtons, setSearchValue, setIsOpenSearch } = coursesSlice.actions;

export default coursesSlice.reducer;
